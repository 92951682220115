

export default {
  name: 'AtomCustomer',
  props: {
    image: {
      required: true,
      type: String,
      default: ""
    },
    alt: {
      required: true,
      type: String,
      default: ""
    },
    link: {
      required: false,
      type: String,
      default: "javascript:void(0)"
    },
  },
  methods: {
    resourceUrl(url) {
      if (url.includes('://')) {
        return url
      }
      return `${this.$store.getters['base/cdnBase']}/${url}`
    }
  }
}
